import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import customParseFormat from "dayjs/plugin/customParseFormat"
import relativeTime from 'dayjs/plugin/relativeTime'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import de from 'dayjs/locale/de'

let day = dayjs.extend(utc).extend(timezone).extend(customParseFormat).extend(relativeTime).extend(weekOfYear)
day.locale(de)

export default day