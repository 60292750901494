/**
 * Main Data provoder for Global Datas like Badges and notifications
 */
import React from "react"
import useBadges from "./badge-context"
import useNotification from "./notification-context"

const AppDataContext = React.createContext()

const useAppDataHook = () => {
  const badge = useBadges()
  const notification = useNotification(() => {
    badge.overwriteValue('notifications', 0)
  })
  return { badge, notification }
}

const AppDataProvider = ({ children }) => {
  const app = useAppDataHook()
  return (
    <AppDataContext.Provider value={app}>{children}</AppDataContext.Provider>
  )
}

const useAppData = () => {
  return React.useContext(AppDataContext)
}

export { useAppData, AppDataProvider }
