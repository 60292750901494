/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export { wrapRootElement } from "./src/components/lib/wrap-root-element"

export const onServiceWorkerUpdateReady = () => {
  caches.keys().then(function(keyList) {
    console.log("clean:caches", keyList)
    return Promise.all(
      keyList.map(function(key) {
        console.log("clean:cache", key)
        return caches.delete(key)
      })
    )
  })
  window.location.reload()
}
