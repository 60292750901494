/**
 * Notifications
 *
 * Usage:
 *
 *  const { notification } = useAppData()
 *  useEffect(() => {
 *    notification.fetch()
 *  }, [])
 *  notification.notifications.map(e => {})
 *
 */
import { useMutation, useLazyQuery } from '@apollo/client'
import gql from "graphql-tag"
import get from "lodash/get"
import { useEffect } from "react"

const QUERY = gql`
  query ProfileBadge {
    notifications {
      count
      limit
      offset
      results {
        id
        channel
        title
        sub_title
        link
        is_read
        read_at
        created_at
      }
    }
  }
`

const MARK_READ = gql`
  mutation NotificationMarkAllRead($to_id: Int!) {
    notification_mark_all_read(to_id: $to_id)
  }
`

const useNotification = callAfterMarkAllRead => {
  const [fetch, { loading, error, data, fetchMore }] = useLazyQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
  })
  const [markAllReadMutation] = useMutation(MARK_READ)

  const notifications = (data => {
    return get(data, "notifications.results", [])
  })(data)

  useEffect(() => {
    const ids_unread = get(data, "notifications.results", [])
      .filter(e => e.is_read === false)
      .map(e => parseInt(e.id, 10))
      .sort((a, b) => {
        return b - a
      })
    if (ids_unread.length > 0) {
      markAllReadMutation({
        variables: { to_id: ids_unread[0] },
      })
      if (callAfterMarkAllRead !== undefined) {
        callAfterMarkAllRead()
      }
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const refetch = () => {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  return { fetch, notifications, loading, error, refetch }
}

export default useNotification
