// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-belege-js": () => import("./../../../src/pages/belege.js" /* webpackChunkName: "component---src-pages-belege-js" */),
  "component---src-pages-belege-search-js": () => import("./../../../src/pages/belege-search.js" /* webpackChunkName: "component---src-pages-belege-search-js" */),
  "component---src-pages-beta-js": () => import("./../../../src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-bi-metric-explorer-js": () => import("./../../../src/pages/bi-metric-explorer.js" /* webpackChunkName: "component---src-pages-bi-metric-explorer-js" */),
  "component---src-pages-clear-js": () => import("./../../../src/pages/clear.js" /* webpackChunkName: "component---src-pages-clear-js" */),
  "component---src-pages-dashboard-ceo-js": () => import("./../../../src/pages/dashboard-ceo.js" /* webpackChunkName: "component---src-pages-dashboard-ceo-js" */),
  "component---src-pages-fulfillment-search-js": () => import("./../../../src/pages/fulfillment-search.js" /* webpackChunkName: "component---src-pages-fulfillment-search-js" */),
  "component---src-pages-helpdesk-js": () => import("./../../../src/pages/helpdesk.js" /* webpackChunkName: "component---src-pages-helpdesk-js" */),
  "component---src-pages-imports-js": () => import("./../../../src/pages/imports.js" /* webpackChunkName: "component---src-pages-imports-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-komm-status-js": () => import("./../../../src/pages/komm-status.js" /* webpackChunkName: "component---src-pages-komm-status-js" */),
  "component---src-pages-logistic-recommendations-js": () => import("./../../../src/pages/logistic-recommendations.js" /* webpackChunkName: "component---src-pages-logistic-recommendations-js" */),
  "component---src-pages-logistic-trend-js": () => import("./../../../src/pages/logistic-trend.js" /* webpackChunkName: "component---src-pages-logistic-trend-js" */),
  "component---src-pages-memo-js": () => import("./../../../src/pages/memo.js" /* webpackChunkName: "component---src-pages-memo-js" */),
  "component---src-pages-more-js": () => import("./../../../src/pages/more.js" /* webpackChunkName: "component---src-pages-more-js" */),
  "component---src-pages-order-pulse-dashboard-js": () => import("./../../../src/pages/order-pulse-dashboard.js" /* webpackChunkName: "component---src-pages-order-pulse-dashboard-js" */),
  "component---src-pages-order-pulse-js": () => import("./../../../src/pages/order-pulse.js" /* webpackChunkName: "component---src-pages-order-pulse-js" */),
  "component---src-pages-order-trend-js": () => import("./../../../src/pages/order-trend.js" /* webpackChunkName: "component---src-pages-order-trend-js" */),
  "component---src-pages-order-trend-per-hour-js": () => import("./../../../src/pages/order-trend-per-hour.js" /* webpackChunkName: "component---src-pages-order-trend-per-hour-js" */),
  "component---src-pages-photobox-js": () => import("./../../../src/pages/photobox.js" /* webpackChunkName: "component---src-pages-photobox-js" */),
  "component---src-pages-photobox-upload-js": () => import("./../../../src/pages/photobox-upload.js" /* webpackChunkName: "component---src-pages-photobox-upload-js" */),
  "component---src-pages-photobox-view-js": () => import("./../../../src/pages/photobox-view.js" /* webpackChunkName: "component---src-pages-photobox-view-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-turnover-table-js": () => import("./../../../src/pages/product-turnover-table.js" /* webpackChunkName: "component---src-pages-product-turnover-table-js" */),
  "component---src-pages-retoure-brain-js": () => import("./../../../src/pages/retoure-brain.js" /* webpackChunkName: "component---src-pages-retoure-brain-js" */),
  "component---src-pages-retoure-pulse-js": () => import("./../../../src/pages/retoure-pulse.js" /* webpackChunkName: "component---src-pages-retoure-pulse-js" */),
  "component---src-pages-service-pulse-js": () => import("./../../../src/pages/service-pulse.js" /* webpackChunkName: "component---src-pages-service-pulse-js" */),
  "component---src-pages-supplier-product-stream-js": () => import("./../../../src/pages/supplier-product-stream.js" /* webpackChunkName: "component---src-pages-supplier-product-stream-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-train-eec-js": () => import("./../../../src/pages/train-eec.js" /* webpackChunkName: "component---src-pages-train-eec-js" */),
  "component---src-pages-train-js": () => import("./../../../src/pages/train.js" /* webpackChunkName: "component---src-pages-train-js" */),
  "component---src-pages-warehouse-brain-js": () => import("./../../../src/pages/warehouse-brain.js" /* webpackChunkName: "component---src-pages-warehouse-brain-js" */),
  "component---src-pages-warehouse-movements-js": () => import("./../../../src/pages/warehouse-movements.js" /* webpackChunkName: "component---src-pages-warehouse-movements-js" */)
}

