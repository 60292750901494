import React from "react"
import { useStorageReducer } from "react-storage-hooks"
import get from "lodash/get"
import { isBrowser } from "../../lib/utils"
import dayjs from '../../lib/utils/dayjs'

const ACTIONS = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
}

const dummyStorage = {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {},
};

const AuthContext = React.createContext()

const initialState = {
  user_id: null,
  token_access: null,
  token_refresh: null,
  expire_at: null,
  profile: null,
  created_at: Date.now(),
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN:
      const token_access = get(action, "payload.token_access")
      if (token_access !== undefined && token_access !== null) {
        localStorage.setItem("token", action.payload.token_access)
      }
      return {
        ...state,
        user_id: get(action, "payload.user_id"),
        token_access: token_access,
        token_refresh: get(action, "payload.token_refresh"),
        expire_at: get(action, "payload.expire_at"),
        profile: get(action, "payload.profile"),
      }
    case ACTIONS.LOGOUT:
      localStorage.removeItem("token")
      localStorage.removeItem("auth")
      return {
        ...state,
        user_id: null,
        token_access: null,
        token_refresh: null,
        profile: null,
      }
    default:
      throw new Error("action not valid")
  }
}

const useAuthHook = () => {
  const [state, dispatch] = useStorageReducer(
    isBrowser() === true ? localStorage : dummyStorage,
    "auth",
    reducer,
    initialState
  )

  const isAuth = () => {
    // Check Expire Time
    const expire_at = get(state, "expire_at")
    if (expire_at !== undefined && expire_at !== null) {
      if (expire_at <= dayjs.utc().unix()) {
        console.log('Auth isAuth user token expired')
        //logout()
        localStorage.removeItem("token")
        localStorage.removeItem("auth")
        return false
      }
    }
    return state.user_id !== null && state.user_id !== undefined
  }

  const login = async (
    user_id,
    token_access,
    token_refresh,
    expire_at,
    profile
  ) => {
    if (user_id === undefined || user_id === null || user_id === "") {
      throw new Error(`useAuthHook.login user_id must set`)
    }
    if (
      token_access === undefined ||
      token_access === null ||
      token_access === ""
    ) {
      throw new Error(`useAuthHook.login token_access must set`)
    }
    if (
      token_refresh === undefined ||
      token_refresh === null ||
      token_refresh === ""
    ) {
      throw new Error(`useAuthHook.login token_refresh must set`)
    }
    dispatch({
      type: ACTIONS.LOGIN,
      payload: {
        user_id: user_id,
        token_access: token_access,
        token_refresh: token_refresh,
        expire_at: expire_at,
        profile: profile,
      },
    })
  }

  const logout = async () => {
    dispatch({ type: ACTIONS.LOGOUT })
  }

  const getTokenAccess = () => {
    return get(state, "token_access")
  }

  const getFromProfile = (key, def) => {
    return get(state, `profile.${key}`, def)
  }

  const getScopes = () => {
    return getFromProfile("scopes", []).map(e => parseInt(e, 10))
  }

  const hasScope = scope => {
    return getScopes().includes(scope)
  }

  return {
    isAuth,
    login,
    logout,
    getTokenAccess,
    hasScope,
    getFromProfile,
    getScopes,
  }
}

const AuthProvider = ({ children }) => {
  const app = useAuthHook()
  return <AuthContext.Provider value={app}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  return React.useContext(AuthContext)
}

export { useAuth, AuthProvider, isBrowser }
