import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  concat,
} from "@apollo/client"
import fetch from "isomorphic-fetch"
import { isBrowser } from "../utils"

const authMiddleware = new ApolloLink((operation, forward) => {
  let token
  if (isBrowser() === true) {
    token = localStorage.getItem("token", "")
  }
  if (
    token !== undefined &&
    token !== null &&
    token !== "" &&
    token !== "undefined"
  ) {
    token = `Bearer ${token}`
  } else {
    token = `Token ${process.env.GATSBY_API_APP_TOKEN}`
  }
  operation.setContext({
    headers: {
      authorization: token ? token : "",
    },
  })
  return forward(operation)
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  fetch,
  link: concat(
    authMiddleware,
    new HttpLink({
      uri: process.env.GATSBY_API_URL || "http://localhost:5001/graphql",
      fetch: fetch,
    })
  ),
})
