import React from "react"
import { useQuery } from '@apollo/client'
import gql from "graphql-tag"
import get from "lodash/get"
import { isBrowser } from "../utils"

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    if (isBrowser() === false) {
      return initialValue
    }
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = value => {
    if (isBrowser() === false) {
      return initialValue
    } else {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    }
  }

  return [storedValue, setValue]
}

const QUERY = gql`
  query ProfileBadge {
    profile_badge {
      issues_not_solved_total
      notifications_unread_total
    }
  }
`

const useBadges = () => {
  const [values, setValue] = useLocalStorage("profile_badges", {
    issues: 0,
    notifications: 0,
  })
  const { loading, error, fetchMore } = useQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (data === undefined || data === null) {
        console.error("useBadges: error loading data")
      } else {
        setValue({
          issues: get(data, "profile_badge.issues_not_solved_total", 0),
          notifications: get(
            data,
            "profile_badge.notifications_unread_total",
            0
          ),
        })
      }
    },
  })

  const getValue = key => {
    return get(values, key, 0)
  }

  const overwriteValue = (key, count) => {
    let d = {
      ...values,
    }
    d[key] = count
    setValue(d)
  }

  const refetch = () => {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  return { getValue, overwriteValue, loading, error, refetch }
}

export default useBadges
