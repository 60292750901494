/**
 * Check if Browser or SSR
 */
const isBrowser = () => typeof window !== "undefined"

/**
 * Add days to date
 * @param {*} date
 * @param {*} days
 */
const addDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

/**
 * Subtract days to date
 * @param {*} date
 * @param {*} days
 */
const subDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() - days)
  return result
}

const numberFormat = (value, def) => {
  if (value === null || value === undefined) {
    if (def !== undefined) {
      return def
    } else {
      return ""
    }
  }
  return value
    .toFixed(2)
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export { isBrowser, addDays, subDays, numberFormat }
