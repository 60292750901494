import React from "react"
import {
  ApolloProvider,
} from "@apollo/client"
import { client } from "./apollo/client"
import { SnackbarProvider } from "notistack"

import { AuthProvider } from "./../lib-admin/auth"
import { AppDataProvider } from "./../lib/data"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <SnackbarProvider maxSnack={3}>
      <AuthProvider>
        <AppDataProvider>{element}</AppDataProvider>
      </AuthProvider>
    </SnackbarProvider>
  </ApolloProvider>
)
